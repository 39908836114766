const LocalNavigation = () => {
  return [
    // {
    //   sectionTitle: ' '
    // },
    {
      "title": "Dashboard",
      "icon": "/images/navigation/android-local-dashboard.svg",
      "path": "/android/local/dashboard"
    },
    {
      title: 'GENERAL FEATURES',

      // icon: '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_9902_8398)"><path d="M0 23.8327H26M23.8333 2.16602H19.5V19.4993H23.8333V2.16602ZM6.5 6.49935H2.16667V19.4993H6.5V6.49935ZM15.1667 19.4993H10.8333V10.8327H15.1667V19.4993Z" stroke="white" stroke-width="1.66667"/></g><defs><clipPath id="clip0_9902_8398"><rect width="26" height="26" fill="currentColor"/></clipPath></defs></svg>',
      children: [
        {
          "title": "Text message",
          "icon": "/images/navigation/android-local-text-message.svg",
          "path": "/android/local/text-messages"
        },
        {
          "title": "Call",
          "icon": "/images/navigation/android-local-call.svg",
          "path": "/android/local/calls"
        },
        {
          "title": "Photo",
          "icon": "/images/navigation/android-local-photo.svg",
          "path": "/android/local/photos"
        },
        {
          "title": "Video",
          "icon": "/images/navigation/android-local-video.svg",
          "path": "/android/local/videos",
          "category": "ALBUM_DETAIL"
        },
        {
          "title": "Wi-Fi network",
          "icon": "/images/navigation/android-local-wi-fi-network.svg",
          "path": "/android/local/wifi-networks"
        },
        {
          "title": "Calendar",
          "icon": "/images/navigation/android-local-calendar.svg",
          "path": "/android/local/calendars"
        },
        {
          "title": "Contact",
          "icon": "/images/navigation/android-local-contact.svg",
          "path": "/android/local/contacts"
        },
        {
          "title": "Installed app",
          "icon": "/images/navigation/android-local-installed-app.svg",
          "path": "/android/local/apps"
        },
        {
          "title": "Gmail",
          "icon": "/images/navigation/android-local-gmail.svg",
          "path": "/android/local/gmail"
        },
        {
          "title": "Easilydo mail",
          "icon": "/images/navigation/android-local-easilydo-mail.svg",
          "path": "/android/local/easilydo-mail"
        },
      ]
    },
    {
      title: 'LOCATION',

      // icon: '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 6.25C24 5.0125 23.01 4 21.8 4H4.2C2.99 4 2 5.0125 2 6.25V19.75C2 20.9875 2.99 22 4.2 22H21.8C23.01 22 24 20.9875 24 19.75V6.25ZM21.8 6.25L13 11.875L4.2 6.25H21.8ZM21.8 19.75H4.2V8.5L13 14.125L21.8 8.5V19.75Z" fill="currentColor"/></svg>',
      children: [
        {
          "title": "GPS location",
          "icon": "/images/navigation/android-local-gps-location.svg",
          "path": "/android/local/locations",
          "category": "LOCATION_DEVICES"
        },
        {
          "title": "Geofence",
          "path": "/android/local/geofence",
          "icon": "/images/navigation/android-local-geofence.svg"
        },
      ]
    },
    {
      title: 'SOCIAL NETWORK',

      // icon: '<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.11111 12.5H8.12333M13 12.5H13.0122M17.8889 12.5H17.9011M24 12.5C24 17.7463 19.0744 21.9999 13 21.9999C11.2017 22.0059 9.42475 21.6208 7.79944 20.873L2 21.9999L3.705 17.5824C2.62578 16.1123 2 14.3691 2 12.5C2 7.25361 6.92556 3 13 3C19.0744 3 24 7.25361 24 12.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      children: [
        {
          "title": "WhatsApp",
          "icon": "/images/navigation/android-local-whatsapp.svg",
          "path": "/android/local/whatsapp"
        },
        {
          "title": "LINE",
          "icon": "/images/navigation/android-local-line.svg",
          "path": "/android/local/line"
        },
        {
          "title": "Messenger",
          "icon": "/images/navigation/android-local-messenger.svg",
          "path": "/android/local/messenger"
        },
        {
          "title": "Telegram",
          "icon": "/images/navigation/android-local-telegram.svg",
          "path": "/android/local/telegram"
        },
        {
          "title": "Viber",
          "icon": "/images/navigation/android-local-viber.svg",
          "path": "/android/local/viber"
        },
        {
          "title": "Tinder",
          "icon": "/images/navigation/android-local-tinder.svg",
          "path": "/android/local/tinder"
        },
        {
          "title": "Kik",
          "icon": "/images/navigation/android-local-kik.svg",
          "path": "/android/local/kik"
        },
        {
          "title": "Snapchat",
          "icon": "/images/navigation/android-local-snapchat.svg",
          "path": "/android/local/snapchat"
        },
        {
          "title": "Teams",
          "icon": "/images/navigation/android-local-teams.svg",
          "path": "/android/local/teams"
        },
        {
          "title": "Skype",
          "icon": "/images/navigation/android-local-skype.svg",
          "path": "/android/local/skype"
        },
        {
          "title": "QQ",
          "icon": "/images/navigation/android-local-qq.svg",
          "path": "/android/local/qq"
        },
        {
          "title": "discord",
          "icon": "/images/navigation/android-local-discord.svg",
          "path": "/android/local/discord"
        },
        {
          "title": "WeChat",
          "icon": "/images/navigation/android-local-wechat.svg",
          "path": "/android/local/we-chat"
        },
        {
          "title": "Facebook",
          "icon": "/images/navigation/android-local-facebook.svg",
          "path": "/android/local/facebook"
        },
        {
          "title": "Youtube",
          "icon": "/images/navigation/android-local-youtube.svg",
          "path": "/android/local/youtube"
        },
        {
          "title": "Instagram",
          "icon": "/images/navigation/android-local-instagram.svg",
          "path": "/android/local/instagram"
        },
        {
          "title": "TikTok",
          "icon": "/images/navigation/android-local-tiktok.svg",
          "path": "/android/local/tik-tok"
        },
      ]
    },
    {
      title: 'LIVE RECORDING',

      children: [
        {
          "title": "Record screen",
          "path": "/android/local/record-screen",
          "icon": "/images/navigation/android-local-record-screen.svg"
        },
        {
          "title": "Record surround",
          "path": "/android/local/record-surround",
          "icon": "/images/navigation/android-local-record-surround.svg"
        },
        {
          "title": "Live Screenshot",
          "path": "/android/local/live-screenshot",
          "icon": "/images/navigation/android-local-live-screenshot.svg"
        },
      ]
    },
    {
      title: 'RESTRICTED',

      children: [
        {
          "title": "Block Applications",
          "path": "/android/local/block-applications",
          "icon": "/images/navigation/applications.svg"
        },
        {
          "title": "Block Wi-Fi",
          "path": "/android/local/block-wifi",
          "icon": "/images/navigation/Wi-Fi.svg"
        },
      ]
    },

    {
      "title": "Permission check",
      "path": "/android/local/permission-check",
      "icon": "/images/navigation/android-local-permission-check.svg"
    },
    {
      "title": "My account",
      "icon": "/images/navigation/android-local-my-account.svg",
      "path": "/pages/account-settings/account"
    }
  ]
}

export default LocalNavigation
