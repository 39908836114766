import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { getDeviceResetTime } from 'src/@api/login'
import dayjs from 'dayjs'
import { disconnectCheck } from 'src/@api/dashboard'

export const fetchResetTime = createAsyncThunk('common/fetchResetTime', async () => {
  let username =
    JSON.parse(localStorage.getItem('DeviceUser')) != null
      ? JSON.parse(localStorage.getItem('DeviceUser')).username
      : ''
  let res = await getDeviceResetTime({ username })

  return {
    basicUpdateDownloadTime:
      res.data.basic_download_time != null ? dayjs(res.data.basic_download_time).format('YYYY-MM-DD HH:mm:ss') : '',
    advancedUpdateDownloadTime:
      res.data.advanced_download_time != null ? dayjs(res.data.advanced_download_time).format('YYYY-MM-DD HH:mm:ss') : '',
    username: username
  }
})

export const advancedShow = createAsyncThunk('common/advancedShow', async () => {
  return true
})

export const fetchDeviceUser = createAsyncThunk('common/fetchDeviceUser', async row => {
  return row
})

export const setCurrentTab = createAsyncThunk('common/setCurrentTab', async value => {
  return value
})

export const getConnectionStatus = createAsyncThunk('common/getConnectionStatus', async () => {
  let username =
    JSON.parse(localStorage.getItem('DeviceUser')) != null
      ? JSON.parse(localStorage.getItem('DeviceUser')).username
      : ''

  const res = await disconnectCheck({
    deviceid: username
  })

  if (res.msg === 'disconnect') {
    return true
  }

  return false
})

export const setConnectionStatus = createAsyncThunk('common/setConnectionStatus', async value => {
  return value
})

export const toggleNavVisibility = createAsyncThunk('common/toggleNavVisibility', async value => {
  return value
})

export const common = createSlice({
  name: 'Common',
  initialState: {
    advancedShow: false,
    deviceUser:
      typeof window !== 'undefined'
        ? JSON.parse(localStorage.getItem('DeviceUser')) != null
          ? JSON.parse(localStorage.getItem('DeviceUser')).username
          : ''
        : '',
    resetTime: {
      basicUpdateDownloadTime: null,
      advancedUpdateDownloadTime: null
    },
    currentTab: '',
    isDisconnect: false,
    navVisible: false
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchResetTime.fulfilled, (state, action) => {
      state.resetTime = action.payload
      state.deviceUser = action.payload.username
    })
    builder.addCase(advancedShow.fulfilled, (state, action) => {
      state.advancedShow = action.payload
    })
    builder.addCase(fetchDeviceUser.fulfilled, (state, action) => {
      console.log(action.payload, 456)
      state.deviceUser = action.payload
    })
    builder.addCase(setCurrentTab.fulfilled, (state, action) => {
      state.currentTab = action.payload
    })
    builder.addCase(getConnectionStatus.fulfilled, (state, action) => {
      state.isDisconnect = action.payload
    })
    builder.addCase(setConnectionStatus.fulfilled, (state, action) => {
      state.isDisconnect = action.payload
    })
    builder.addCase(toggleNavVisibility.fulfilled, (state, action) => {
      state.navVisible = action.payload
    })
  }
})

export default common.reducer
