import axios from '/src/utils/request'
import qs from 'qs'

//apk注册码
export const getCode = options => axios.get('/api/user/get-device-code', options)

//apk验证注册码是否绑定
export const CheckCode = options => axios.post('/api/user/check-device-code', options)

// 判断本地app是否最新版本
export const checkAppLastVersion = options => axios.get('/api/get-app/version?' + qs.stringify(options))

// 本地app请求刷新
export const androidLocalRefresh = options => axios.post('/api/device/android-local-reset-data', options)

// 本地app录制状态获取
export const androidLocalRecordQuestionRrecycleGet = options =>
  axios.get('/api/device/android-local-record-question-recycle-get?' + qs.stringify(options))

//AndRoid-Local选择moto机型时发送邮件
export const localPhoneSendEmail = options => axios.post('/api/definition/target-phone', options)

//Block app && Block wifi获取应用解锁状态
export const setBlock = options => axios.post('/api/device/android-local-device-type-limit', options)